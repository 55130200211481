import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Assalamu Alaikum`}</strong></p>
    <p>{`I'm Mahmudul Hasan, a software engineer specializing in the WISC stack. Currently, I work at `}<a parentName="p" {...{
        "href": "https://selisegroup.com/"
      }}>{`SELISE Digital Platforms`}</a>{` as a Staff Software Engineer.`}</p>
    <p>{`Previously, I worked at:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.vivasoftltd.com/"
        }}>{`Vivasoft Limited`}</a>{` as a Level 2 Software Engineer.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://astgd.com/"
        }}>{`ASTGD`}</a>{` as a Junior Software Developer.`}</li>
    </ul>
    <p>{`In addition to my software career, I run a government-registered pet company called `}<a parentName="p" {...{
        "href": "https://pienteger.com"
      }}>{`Pienteger`}</a>{` (pronounced as "pie-integer").
I dream of growing it into a tech giant like Microsoft or Google one day.`}</p>
    <p>{`Here's a photo of me, taken in 2022, while chilling at St. Martin's Island, Bangladesh.`}</p>
    <img {...{
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      },
      "src": "/DSC_0089_Edited.JPG",
      "alt": "A smiling photo of Mahmudul Hasan"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      