import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
    `}<h3 parentName="div" {...{
        "id": "social"
      }}>{`Social`}</h3>{`
    `}<p parentName="div">{`
        `}<a parentName="p" {...{
          "href": "https://github.com/MahmudX"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/github/followers/mahmudx?style=for-the-badge&logo=GitHub&label=Follow&color=0077B5&labelColor=black",
            "alt": "GitHub followers"
          }}></img></a>{`
        `}<a parentName="p" {...{
          "href": "https://www.linkedin.com/in/mahmudxyz"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/badge/%20-Connect-black?style=for-the-badge&color=14171A&labelColor=0077B5&logo=linkedin&logoColor=ffffff",
            "alt": "LinkedIn Connect"
          }}></img></a>{` `}<a parentName="p" {...{
          "href": "https://www.instagram.com/mahmudxyz"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/badge/%20-Follow-black?style=for-the-badge&color=14171A&labelColor=d81b60&logo=instagram&logoColor=ffffff",
            "alt": "Insta Follow"
          }}></img></a>{` `}<a parentName="p" {...{
          "href": "https://www.facebook.com/mahmudofficialid"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/badge/%20-Connect-black?style=for-the-badge&color=14171A&labelColor=1976d2&logo=facebook&logoColor=ffffff",
            "alt": "Facebook Follow"
          }}></img></a>{` `}<a parentName="p" {...{
          "href": "https://stackoverflow.com/users/6662984/mahmudul-hasan"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/badge/%20-Questions-black?style=for-the-badge&labelColor=FE7A16&logo=Stack%20Overflow&logoColor=fff",
            "alt": "Stackoverflow"
          }}></img></a>{`
        `}<a parentName="p" {...{
          "href": "https://www.leetcode.com/mahmudx"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/badge/%20-LeetCode-2EC866?style=for-the-badge&color=14171A&labelColor=red&logo=leetcode&logoColor=ffffff",
            "alt": "LeetCode"
          }}></img></a>{`
        `}<a parentName="p" {...{
          "href": "https://www.hackerrank.com/mahmudx"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/badge/%20-Hacker%20Rank-2EC866?style=for-the-badge&color=14171A&labelColor=2EC866&logo=HackerRank&logoColor=ffffff",
            "alt": "Hacker Rank"
          }}></img></a>{`
        `}<a parentName="p" {...{
          "href": "https://codeforces.com/profile/MahmudX"
        }}><img parentName="a" {...{
            "src": "https://cfrating.rbs.workers.dev/?username=mahmudx"
          }}></img></a>{`
    `}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      